import React, { useState, useRef } from 'react';
import { Mask } from 'antd-mobile';
import { withTranslation, WithTranslation } from 'react-i18next';

import './GoldEgg.css';

import Ellipse from '../../utils/Ellipse';
import loadImgs from '../..//utils/loadImgs';

interface IState {
  baseImage: any; // 金蛋底座图片
  eggList: any; // 金蛋列表
  eggImage: string;
  hammer: string; // 锤子图片,
  hammer_index: number;
  hammerAnimFinished: boolean;
  egg_shell_1: any;
  egg_shell_2: any;
}

class GoldEggComponent extends  React.Component<WithTranslation, IState> {


  private config: any;

  private zajindanBox: any = React.createRef();

  private lotteryIng: boolean = false; // 是否正在抽奖

  private ellipse: any; // 椭圆本体

  private points = []; // 需要运动的点

  private pointsNumber: number; // 默认需要100个定位点

  private speed: number; // 旋转速度

  private moving: boolean; // 蛋蛋是否正在移动

  private clicked_index: number; // 被击中的蛋

  private touchStart: Function; // 响应事件，调用父方法

  constructor(props) {
    super(props);

    this.config = props.config;
    this.pointsNumber = props.config.pointsNumber || 100;
    this.speed = props.config.speed || 40; // 旋转速度
    this.touchStart = props.touchStart;

    this.moving = false; // 蛋蛋是否正在移动
    this.clicked_index = -1; // 被击中的蛋
    

    this.state = {
      eggImage: this.config.eggImage, // 金蛋图片
      egg_shell_1: this.config.egg_shell_1, // 金蛋裂开 瓣1
      egg_shell_2: this.config.egg_shell_2, // 金蛋裂开 瓣2
      hammer: this.config.hammer, // 锤子图片,
      hammer_index: -1, // 当该值与某一个 
      hammerAnimFinished: false,// 锤子的动画是否已完成
      baseImage: this.config.baseImage, // 金蛋底座图片
      eggList: this.config.eggList, // 金蛋列表
    };
  }

  componentDidMount() {
    this.preloadImgs();

    if (typeof this.config.eggList[0] != 'object') {
      this.setState({
        eggList: this.config.eggList.map(item => {
          return {
            point: item || 0,
            eggSrc: '',
            egg_shell_1: '',
            egg_shell_2: ''
          };
        })
      })
      
    } else {
      this.setState({
        eggList: JSON.parse(JSON.stringify(this.config.eggList))
      })
    }

    this.initPoints();
    this.moving = true;
    this.moveEggs();

    setTimeout(() => {
      // this.goToStart();
    }, 10000);
  }

  private initPoints() {
    const box = this.zajindanBox.current;
    // const box = this.$refs['zajindanBox'];

    if (!box) {
      return;
    }
    if (!box.clientWidth) {
      setTimeout(() => {
        this.initPoints();
      }, 200);
      return;
    }

    let x = 0;
    let y = 0;
    let width = box.clientWidth;
    let height = box.clientHeight;

    this.ellipse = new Ellipse(x, y, width, height);
    this.points = this.ellipse.getPoints(this.pointsNumber).reverse();
  }

  private getLeft(index) {
    const point: any = this.points[index];

    if (point) {
      return point.x;
    }
    return 0;
  }

  private getTop(index) {
    const point: any = this.points[index];

    if (point) {
      return point.y;
    }
    return 0;
  }

  private getScale(index) {
    const point: any = this.points[index];

    if (!point) {
      return 1;
    }
    return 1 + (point.y / this.ellipse.height) * 0.5;
  }

  private getZIndex(index) {
    const point: any = this.points[index];

    if (!point) {
      return 0;
    }
    if (point.y < this.ellipse.y) {
      return 0;
    }
    if (point.y < this.ellipse.y + this.ellipse.height * 0.25) {
      return 1;
    }
    return 2;
  }

  private clickEgg(index) {
    this.clicked_index = index;
    // 在这里向父组件申请抽奖
    if (this.lotteryIng) {
      console.log('正在抽奖');
      return;
    }
    this.lotteryIng = true;
    this.touchStart();
    // this.$emit('touchStart');
  }

  private launchHomeLottery() {
    return new Promise((resolve, reject) => {
      // 在这里向父组件发起抽奖（请求）
      console.log('在这里向父组件发起抽奖');
      // this.$emit('lotteryLaunch', { resolve, reject });
    });
  }

  private goToStart() {
    if (this.state.hammer_index !== -1) {
      return;
    }

    this.moving = false;
    this.setState({ hammer_index: this.clicked_index });
    

    this.setState({ hammerAnimFinished: true });


    // this.$emit('lotteryLaunch');

    // Promise.all([this.launchHomeLottery()])
    //   .then(data => {
    //     // 在这里告诉父组件，抽奖的动画已经完成，可以显示结果了
    //     this.$emit('lotteryFinish', data[0]);
    //   })
    //   .catch(err => {});
    // .finally(() => {
    //   this.lotteryIng = false;
    // });
  }
  
  private moveEggs() {
    if (!this.moving) {
      return;
    }
    this.setState({
      eggList: this.state.eggList.map(item => {
        item.point = (item.point + 1) % this.pointsNumber;
        return item;
      })
    })

    setTimeout(() => {
      this.moveEggs();
    }, this.speed);
  }

  private resetData() {
    this.setState({
      hammer_index: -1,
      hammerAnimFinished: false,
    })
    this.moving = true;
    this.lotteryIng = false;
    this.moveEggs();
  }

  private preloadImgs() {
    const {
      baseImage,
      eggImage,
      hammer,
      eggList,
      egg_shell_1,
      egg_shell_2,
      
    } = this.config;
    
    let img: any[] = [];
    eggList.map((item) => {
      if (item.eggSrc && img.indexOf(item.eggSrc) == -1) {
        img.push(item?.eggSrc);
      }
      if (item.egg_shell_1 && img.indexOf(item.egg_shell_1) == -1) {
        img.push(item.egg_shell_1);
      }
      if (item.egg_shell_2 && img.indexOf(item.egg_shell_2) == -1) {
        img.push(item.egg_shell_2);
      }
    });

    loadImgs([
      baseImage,
      eggImage,
      hammer,
      egg_shell_1,
      egg_shell_2,
      ...img
    ]).then(() => {});
  }  


  render() {

    const { zajindanBox } = this;

    const { baseImage, eggList, eggImage, hammer, hammer_index, hammerAnimFinished, egg_shell_1, egg_shell_2 } = this.state;

    return (
      <>
        <Mask opacity={.7}>
          <section className="zajindan">
            <img className="zajindan__bed" src={baseImage} />
            <div className="zajindan__box" ref={zajindanBox}>
              {(hammerAnimFinished === false) && <img className="zajindan__hammer_still" src={hammer} />}
              {eggList.map((item: any, index) => {
                return <div key={index} className="zajindan__egg" style={{
                  transform: `translate(${this.getLeft(item.point)}px, ${this.getTop(item.point)}px) scale(${this.getScale(item.point)})`,
                  zIndex: `${this.getZIndex(item.point)}`
                }}
                onClick={() => {
                  this.clickEgg(item.point);
                  this.goToStart();
                }}>
                  <img src={item.eggSrc || eggImage}
                    className={`zajindan__egg-img ${(hammer_index !== -1 && hammer_index !== item.point || hammerAnimFinished === true) ? 'hidden' : ''}`} />
                  {(hammerAnimFinished === true && hammer_index === item.point) && <img className="zajindan__egg-shell-1" src={item.egg_shell_1 || egg_shell_1} />}
                  {(hammerAnimFinished === true && hammer_index === item.point) && <img className="zajindan__egg-shell-2" src={item.egg_shell_2 || egg_shell_2} />}
                  {(hammer_index === item.point) && <img className="zajindan__hammer" src={hammer} />}
                </div>
              })}
            </div>
          </section>
        </Mask>
       </>
    );
  }
};

export const GoldEgg = withTranslation()(GoldEggComponent);