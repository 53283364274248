import styles from './App.module.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Vconsole from 'vconsole';

import { HomePage, SignInPage, RegisterPage, DetailPage, GamePage } from './pages';

// const vconsole = new Vconsole();

function App() {
  return (
    <div className={styles.App}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={HomePage}></Route>
          <Route path="/signin" component={SignInPage}></Route>
          <Route path="/register" component={RegisterPage}></Route>
          <Route path="/detail/:touristRouteId" component={DetailPage}></Route>
          <Route path="/game" component={GamePage}></Route>
          <Route render={() => <h1>404</h1>} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
