import ajax from './ajax'

// const BASE_URL = 'http://119.23.248.4:9090';
// const BASE_URL = 'http://172.16.78.207:9090'; // 洪亮接口地址
const BASE_URL = 'https://choin.com.cn/yinger/api'; // 生产接口地址

// 请求todo列表
export const getTodoList = () => ajax(BASE_URL + '/shop');

export const sendScore = (data) => ajax(BASE_URL + '/summer/c/game/score', data, 'POST');

export const luckyDraw = (data) => ajax(BASE_URL + '/summer/c/draw', data, 'POST');

export const sendDeliveryAddress = (data) => ajax(BASE_URL + '/summer/c/submit/address', data, 'POST');

export const isAddress = (data) => ajax(BASE_URL + '/summer/c/is/address', data, 'GET');

export const pushPoint = (data) => ajax(BASE_URL + '/summer/c/push/point', data, 'POST');

export const queryPointGive = (data) => ajax(BASE_URL + '/summer/c/point/give', data, 'POST');
