import React, { useState } from 'react';
import {
  Form,
  Input,
  Dialog,
  List,
  Mask
} from 'antd-mobile'
import * as PIXI from 'pixi.js';
import { withTranslation, WithTranslation } from 'react-i18next';
import { sendScore, luckyDraw, sendDeliveryAddress, isAddress, pushPoint, queryPointGive } from '../../api/index';
import {
  GoldEgg,
} from '../../components';
import { CSSTransition } from 'react-transition-group';
// import { END_STATUS, SPEED_LEVEL, Game } from '../../services/game-engine';
// import game from '../../services/game.js'
import Bump from '../../services/bump';
import styles from './GamePage.module.css';
import { options } from './data'




import { Cascader, Toast } from 'antd-mobile'

import ADDONE from "../../assets/images/game/plusone.png";
import ADDONE2 from "../../assets/images/game/plusone.png";
import ADDONE3 from "../../assets/images/game/plusone.png";
import DRAGON from "../../assets/images/game/dragon.png";
import logo1 from "../../assets/images/game/logo1.png";
import logo2 from "../../assets/images/game/logo2.png";
import logo3 from "../../assets/images/game/logo3.png";
import logo4 from "../../assets/images/game/logo4.png";
import logo5 from "../../assets/images/game/logo5.png";
import logo6 from "../../assets/images/game/logo6.png";
import drinks1 from "../../assets/images/game/drinks1.png";
import clock from "../../assets/images/game/clock.png";
import bomb from "../../assets/images/game/bomb.png";
import icecream from '../../assets/images/game/icecream.png'

import baseImage from '../../assets/images/gold-egg/base_zajindan.png'; // 金蛋底座图片
import eggImage from '../../assets/images/gold-egg/egg.png'; // 金蛋图片
import hammer from '../../assets/images/gold-egg/hammer.png'; // 锤子图片
import egg_shell_1 from '../../assets/images/gold-egg/egg_shell_1.png'; // 金蛋裂开 瓣1
import egg_shell_2 from '../../assets/images/gold-egg/egg_shell_2.png'; // 金蛋裂开 瓣2
import eggSrc from '../../assets/images/gold-egg/egg.png'; // 金蛋图片单独配置

interface ResourceImage {
  src: string;
  top: number;
  left: number;
  width: number;
  height: number;
}

interface Resources {
  // 掉落元素图片
  fallingItemElement: ResourceImage;
  // 障碍物
  fallingBlockElement: ResourceImage;
  // 接收掉落元素图片
  receiveElement: ResourceImage;
  // 得分 +1 图片
  scoreAddElement: ResourceImage;
}

interface CallbackListener {
  (n: number): void;
}

interface GameProps {
  /** 游戏运行容器元素 */
  el: null | HTMLElement;
  /** 所有资源 */
  resources: Resources;
  /** 限时 */
  timeLimit?: number;
  /** 速度等级 */
  speedLevel: SPEED_LEVEL;

  /** 时间变化监听 */
  timeListeners?: CallbackListener[];
  /** 得分变化监听 */
  scoreListeners?: CallbackListener[];
  /** 游戏结束 */
  gameOver?: ({ status: END_STATUS, score: number }) => void;
}

enum FALL_TYPES {
  ITEM = 'fallingItemElement',
  BLOCK = 'fallingBlockElement',
}

/** 碰撞判断 */
const bump = new Bump(PIXI);

const IMAGE = {
  ADDONE,
  ADDONE2,
  ADDONE3,
  DRAGON,
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  drinks1,
  clock,
  bomb,
  icecream
};

export enum STAGE {
  WAITING = 'waiting',
  RUNNING = 'running',
  PAUSE = 'pause',
  STOP = 'stop',
}

export enum SPEED_LEVEL {
  SLOW = 'slow',
  MIDDLE = 'middle',
  FAST = 'fast',
}

export enum END_STATUS {
  TIMEOUT = 'timeout',
  FAILED = 'failed',
}

enum TIME_READY {
  THREE = 'three',
  TWO = 'two',
  ONE = 'one',
  GO = 'go',
  NONE = 'none',
}

/** 游戏倒计时 3 - 图片 */
export const GAME_MAIN_IMAGE_COUNT_DOWN_THREE = `GAME_MAIN_IMAGE_COUNT_DOWN_THREE`;
/** 游戏倒计时 2 - 图片 */
export const GAME_MAIN_IMAGE_COUNT_DOWN_TWO = `GAME_MAIN_IMAGE_COUNT_DOWN_TWO`;
/** 游戏倒计时 1 - 图片 */
export const GAME_MAIN_IMAGE_COUNT_DOWN_ONE = `GAME_MAIN_IMAGE_COUNT_DOWN_ONE`;
/** 游戏倒计时 GO - 图片 */
export const GAME_MAIN_IMAGE_COUNT_DOWN_GO = `GAME_MAIN_IMAGE_COUNT_DOWN_GO`;

interface IState {
  /** 游戏倒计时, 距离游戏开始 3, 2, 1 倒数 */
  timeReady: TIME_READY;
  /** 游戏时长 */
  timeUsed: number;
  /** 游戏积分 */
  score: number;
  /** 资源加载 */
  resourcesPercent: number;
  Authorization: string;
  showTip: boolean;
  tip: string;
  goldEggConfig: object;
  showGoldEgg: boolean;
}

function Tip(props) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Mask visible={props.visible} onMaskClick={() => setVisible(false)}>
        <div className={styles['start-wrap']}>
          <img className={styles['tip']} src="https://choin.com.cn/projects/yinger/asserts/img/tip.png" alt="" />
          <img onClick={(e) => {
            props.start();
          }} className={styles['start-game']} src="https://choin.com.cn/projects/yinger/asserts/img/start.png" alt="" />
        </div>
      </Mask>
    </>
  )
}

function Add(props) {

  return (
    <>
      {/* <CSSTransition
        timeout={400}
        classNames={{
          enter: styles.animate__animated,
          enterActive: styles.animate__zoomIn,
          exit: styles.animate__animated,
          exitActive: styles.animate__zoomOut,
        }}> */}
        <a className={styles['add-tip']}>{props.text}</a>
      {/* </CSSTransition> */}
    </>
  )
}

// 渲染所选值
function RenderChildrenDemo(props) {
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState<string[]>([])
  return (
    <>
      <List.Item extra={<Cascader
        options={options}
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
        value={value}
        onConfirm={setValue}
        onSelect={(val, extend) => {
          props.setPrefixAddress(val);
          console.log('onSelect', val, extend.items)
        }}
      >
        {items => {
          if (items.every(item => item === null)) {
            return ''
          } else {
            return items.map(item => item?.label ?? '').join('-')
          }
        }}
      </Cascader>} onClick={() => { 
         setVisible(true)
      }}>
        <div className={styles['user-info-label']} onClick={
          () => {
            setVisible(true)
          }
        }>地区信息</div>

      </List.Item>

    </>

  )
}


class GamePageComponent extends React.Component<WithTranslation, IState> {

  private refView: any = React.createRef();

  private app: any;

  private isInit: boolean = false;

  /** 当前游戏阶段 */
  private stage: STAGE = STAGE.WAITING;

  private dragSprite?: PIXI.Sprite;

  private fallingElements: Array<any> = [];


  private plusElements: PIXI.Sprite[] = [];

  private addMinusElements: PIXI.Sprite[] = [];

  /** 得分元素追加定时器 */
  private itemTimer: NodeJS.Timeout | null = null;

  /** 障碍物追加定时器 */
  private blockTimer: NodeJS.Timeout | null = null;

  /** 生成粽子基础间隔时间 */
  private baseAppendTime = 300;

  /** 生成粽子变动时间 */
  private dynamicAppendTime = 300;

  private speed = 10;

  private score = 0;

  /** 得分更新定时器 */
  private scoreListeners: CallbackListener[] = [];

  private prizeId = "";

  /** 奖品名称 */
  private prizeName = "";

  /** 是否实体店 */
  private isStore = '0';

  private inviteUserId = '';

  private deliveryAddress = {
    phone: "",
    prefixAddress: "",
    prizeId: "",
    suffixAddress: "",
    userName: "",
  };

  constructor(props) {
    super(props);
    this.state = {
      timeReady: TIME_READY.NONE,
      timeUsed: 10,
      score: 0,
      resourcesPercent: 0,
      Authorization: "",
      showTip: true,
      tip: "",
      showGoldEgg: false,
      goldEggConfig: {
        pointsNumber: 100, // 定位点个数
        speed: 40, // 转动速度
        baseImage, // 金蛋底座图片
        eggImage, // 金蛋图片
        hammer, // 锤子图片
        egg_shell_1, // 金蛋裂开 瓣1
        egg_shell_2, // 金蛋裂开 瓣2
        // eggList: [0, 34, 67]
        eggList: [
          {
            point: 0, // 金蛋1 的定位
            eggSrc, // 金蛋图片单独配置
            egg_shell_1,
            egg_shell_2,
          },
          {
            point: 34
          },
          {
            point: 67
          }
        ]
      }
    };
  }

  async componentDidMount() {
    console.log('abcd 0003')


    try {
      window["wx"].miniProgram.updateAppMessageShareData({
        title: 'test 0002', // 分享标题
        desc: 'test 0003', // 分享描述
        link: 'test 0004', // 分享链接，（这个地址好像可以随意填写）
        imgUrl: 'https://choin.com.cn/projects/yinger/asserts/img/select-guide.png', // 分享图标
        success: function (setSuccess) {
            // 设置成功
            console.log("设置成功", setSuccess)
        }
      });
    } catch(err) {
      console.error(err);
    }
   






    // 设置token
    const utm = new URLSearchParams(window.location.href.split("?")[1]);
    if (utm.get("Authorization")) {
      localStorage.setItem("Authorization", utm.get("Authorization") || "");
    }

    this.isStore = utm.get("isStore") || '0';

    if (utm.get("inviteUserId") !== 'undefined' && utm.get("inviteUserId") !== undefined) {
      this.inviteUserId = utm.get("inviteUserId") || "";
    }
    
    this.createGame();
    this.init();
    
    const res = (await isAddress()).data;
    if (res.data.isSubmit) {
      // do nothing
    } else {
      this.prizeName = res.data.prizeName;
      this.setState({
        showTip: false
      });
      this.fillAddress();
    }
  }

  /** 读取当前画布尺寸 */
  private getViewSize() {
    const that = this;
    if (!this.refView.current) {
      console.log(this.refView);
    }
    const offsetWidth = that.refView.current.offsetWidth;
    const offsetHeight = that.refView.current.offsetHeight;

    return {
      width: offsetWidth,
      height: offsetHeight,
    };
  }

  /** 获取图片代理地址 */
  private createProxyImageSprite = (src: string) =>
    PIXI.Texture.from(src);


  /** 根据类型生成掉落 sprite(得分元素 + 障碍物) */
  private createFallingSprite(type: any) {
    const that = this;
    const { width, height } = { width: 165, height: 141 };

    const sprite = PIXI.Sprite.from(IMAGE[type]);


    sprite.width = width;
    sprite.height = height;
    sprite.scale.set(0.4);
    const viewSize = this.getViewSize();

    sprite.x = Math.floor(Math.random() * (viewSize.width - width / 2));
    sprite.y = -height + 200;

    switch (type) {
      case FALL_TYPES.BLOCK:
        break;
      default:
        // 设置倾斜角度
        // sprite.rotation = Math.PI / 2 - Math.PI * Math.random();
    }

    this.app.stage.addChild(sprite);
    this.fallingElements.push({
      type,
      sprite,
      speed: this.speed,
    });

    return sprite;
  };

  /** 随机新增掉落物时间 */
  private getAppendTime() {
    // 获取"慢速"与"当前速度"比例, 比例越小, 表示当前速度越快
    // const zoom = this.getSpeed(SPEED_LEVEL.SLOW) / this.speed;
    const zoom = 0.6;
    // 速度愉快, 生成掉落物间隔越短
    return Math.floor(
      (Math.random() * this.dynamicAppendTime + this.baseAppendTime) * zoom
    );
  }

  private randomType() {
    const types = ['logo1', 'logo2', 'logo3', 'logo4', 'logo5', 'logo6', 'drinks1', 'icecream', 'clock', 'bomb'];
    return types[parseInt('' + (Math.random() * types.length), 10)]
  }

  /** 追加积分掉落物 */
  private appendItemSprite = () => {
    this.createFallingSprite(this.randomType());
    const duration = this.getAppendTime();

    this.clearItemTimer();
    this.itemTimer = setTimeout(() => {
      this.appendItemSprite();
    }, duration);
  };

  /** 清理物品掉落定时器 */
  private clearItemTimer = () => {
    if (this.itemTimer) {
      clearTimeout(this.itemTimer);
      this.itemTimer = null;
    }
  };

  private removeAllFallingElements() {
    this.fallingElements.forEach(fallingElement => {
      this.app.stage.removeChild(fallingElement.sprite);
    });
    this.plusElements.forEach(item => {
      this.app.stage.removeChild(item);
    });
    this.addMinusElements.forEach(item => {
      this.app.stage.removeChild(item);
    });
  }


  /** 追加障碍物 */
  private appendBlockSprite = () => {
    this.clearBlockTimer();
    const duration = Math.floor(2000 + Math.random() * 4000);

    this.blockTimer = setTimeout(() => {
      const type = FALL_TYPES.BLOCK;
      this.createFallingSprite(type);
      this.appendBlockSprite();
    }, duration);
  };

  /** 开始掉落物品, app ticker 添加执行方法 */
  private startFallingSprite() {
    this.app.ticker.add(this.running);
  }

  /** 停止掉落物品, app ticker 移除执行方法 */
  private stopFallingSprite() {
    this.app.ticker.remove(this.running);
  }

  /** 添加得分 +1 元素 */
  private createPlusSprite = (sprite: PIXI.Sprite, score) => {
    const texture = this.createProxyImageSprite(IMAGE['ADDONE']);
    const plus = new PIXI.Sprite(texture);
    const { width, height } = { width: 362 / 2, height: 181 / 2 };
    plus.width = width;
    plus.height = height;
    plus.y = sprite.y;
    plus.x = Math.floor(sprite.x + (sprite.width - width) / 2);
    sprite.anchor.set(0.5);
    this.plusElements.push(plus);
    this.app.stage.addChild(plus);
  };

  /** 添加得分 +1 元素 */
  private createAddMinusSprite = (sprite: PIXI.Sprite, score) => {
    const texture = this.createProxyImageSprite(IMAGE['ADDONE']);
    const plus = new PIXI.Sprite(texture);
    const { width, height } = { width: 362 / 2, height: 181 / 2 };
    plus.width = width;
    plus.height = height;
    plus.y = sprite.y;
    plus.x = Math.floor(sprite.x + (sprite.width - width) / 2);
    sprite.anchor.set(0.5);
    this.plusElements.push(plus);
    this.app.stage.addChild(plus);
  };

  private getTip(type: string): string {
    if (type == 'clock') {
      return '+3s'
    }
    if (type == 'bomb') {
      return '-3s'
    }

    //不同物品增加分数
    if (['drinks1'].includes(type)) {
      return '+2';
    } else if (['icecream'].includes(type)) {
      return '+1'
    } else {
      return '+3'
    }
  }

  /** 心跳执行方法, 掉落物 Y 轴移动 */
  private running = (delta: number) => {
    if (this.stage !== STAGE.RUNNING) return;

    const viewSize = this.getViewSize();
    const { fallingElements, plusElements } = this;
    let score = 0;
    // 处理掉落元素(使用 for 遍历倒序遍历, 保证可以在遍历过程中进行元素移除)
    for (let i = fallingElements.length - 1; i >= 0; i--) {
      const element = fallingElements[i];
      if (bump.hit(element.sprite, this.dragSprite)) {
        console.log(element.type)
        if (element.type === FALL_TYPES.BLOCK) {
          this.stop(END_STATUS.FAILED);
        } else {

          //不同物品增加分数
          if (['drinks1'].includes(element.type)) {
            score += 2
          } else if (['icecream'].includes(element.type)) {
            score += 1
          } else {
            score += 3;
          }

          if (element.type == 'clock') {
            this.setState({ timeUsed: this.state.timeUsed + 3 });
          }
          if (element.type == 'bomb') {
            this.setState({ timeUsed: (this.state.timeUsed - 3) >= 0 ? (this.state.timeUsed - 3) : 0 });
          }

          this.createPlusSprite(element.sprite, score);
          this.setState({ tip: this.getTip(element.type) });
          setTimeout(() => {
            this.setState({ tip: '' });
          }, 300);
          this.removeSprite(i);
        }
      } else if (element.sprite.y > viewSize.height) {
        this.removeSprite(i);
      } else {
        element.sprite.y += element.speed * delta * 0.4;
      }
    }
    if (score > 0) {
      this.addScore(score);
    }
    // 处理 +1 元素
    // if (!plusElements.length) return;

    for (let i = plusElements.length - 1; i >= 0; i--) {
      const plus = plusElements[i];
      if (plus.alpha <= 0) {
        this.removePlus(i);
      } else {
        plus.alpha -= 0.02 * delta;
        plus.y -= 0.005 * delta;
      }
    }
  };

  //填写地址
  private fillAddress() {
    const that = this;
    const setPrefixAddress = function (val) {
      that.deliveryAddress.prefixAddress = '' + (val[0] || '') + (val[1] || '') + (val[2] || '');
    };
  
    Dialog.alert({
      content: (
        <>
          <div className={styles['winning-message']}>恭喜你获得了</div>
          <div className={styles['prize-text']}>{that.prizeName}</div>

          <div className={styles['user-info-form']}>
            <Form
              layout='horizontal'
            >
              <Form.Header ><>
                <div style={{ color: '#000000', fontSize: '0.88rem' }}>收货信息</div>
              </></Form.Header>
              <Form.Item
                name='name'
                label={(<div className={styles['user-info-label']} >收货人</div>)}
              >
                <Input className={styles['user-info-input']} onChange={(e) => {
                  that.deliveryAddress.userName = e;
                }} placeholder='姓名' />
              </Form.Item>
              <Form.Item
                name='phone'
                label={(<div className={styles['user-info-label']} >手机号</div>)}
              >
                <Input className={styles['user-info-input']} onChange={(e) => {
                  that.deliveryAddress.phone = e;
                }} placeholder='手机号' />
              </Form.Item>
              <RenderChildrenDemo setPrefixAddress={setPrefixAddress} />
              <Form.Item
                name='address'
                label={(<div className={styles['user-info-label']} >详细地址</div>)}

              >
                <Input className={styles['user-info-input']} onChange={(e) => {
                  that.deliveryAddress.suffixAddress = e;
                }} placeholder='街道门牌信息' />
              </Form.Item>
            </Form>

          </div>
        </>
      ),
      confirmText: (
        <div className={styles['winning-btn']} >确认</div>),
      bodyClassName: styles['user-info-dialog'],
      onConfirm: async () => {
        if (!/^1[3-9]\d{9}$/.test(this.deliveryAddress.phone)) {
          Toast.show({
            icon: 'fail',
            content: '手机号错误',
          });
          throw new Error();
        }
        if (!this.deliveryAddress.userName || !this.deliveryAddress.prefixAddress || !this.deliveryAddress.suffixAddress) {
          Toast.show({
            icon: 'fail',
            content: '信息不完整',
          });
          throw new Error();
        }
        await sendDeliveryAddress({ ...that.deliveryAddress, prizeId: that.prizeId });
        Toast.show({
          icon: 'success',
          content: '提交成功',
        });
        setTimeout(() => {
          window["wx"].miniProgram.navigateTo({
            url: `/pages/index/index?inviteUserId=${that.inviteUserId}`
          });
        }, 2000);
      }
    })
  }

  private handleDraw = async () => {
    // 抽奖
    const res = (await luckyDraw()).data;
    if (res.data.isWin) {
      //抽奖之后填写地址
      this.prizeId = res.data.prizeId;
      this.prizeName = res.data.prizeName;
      //抽奖之后填写地址
      this.fillAddress();
    } else {
      Dialog.confirm({
        content: (
          <>
            <div className={styles['lucky-draw-content-message']} style={{ marginTop: '3.91rem' }}>很遗憾 未中奖</div>
          </>
        ),
        confirmText: (
          <div className={styles['not-winning']} >返回首页</div>),
        cancelText: (
          <div className={styles['not-winning']} >再试一次</div>),
        bodyClassName: styles['game-over'],
        onConfirm: () => {
          window["wx"].miniProgram.navigateTo({
            url: '/pages/index/index'
          });
        },
        onCancel: () => {
          window["wx"].miniProgram.navigateTo({
            url: '/pages/game/game'
          });
        }
      });
    }
  }

  private  tryGoldEgg = async () => {
    this.setState({
      showGoldEgg: true
    })
  }

  /** 游戏结束, 停止游戏 */
  public stop = async (status: END_STATUS) => {
    const that = this;
    this.clearItemTimer();
    this.clearBlockTimer();

    sendScore({
      "score": this.score,
      "userId": "0001",
      "userType": 0
    });
    // 结束游戏, 停止定时器
    // this.chronograph.stop();
    this.removeAllFallingElements();

    const res = (await queryPointGive({ taskType: 1, inviteUserId: this?.inviteUserId })).data;
    console.log(`queryPointGive res: `, res);
    if (!res.data) {
      // 游戏任务达成，获得50积分
      Toast.show({
        content: `参与游戏任务达成，恭喜获得${this.isStore == '2' ? '38' : '20'}积分`,
      });
      await pushPoint({
        inviteUserId: this?.inviteUserId,
        taskType: 1
      });
    }

    this.setStage(STAGE.STOP);
    setTimeout(() => {
      if (this.score < 80) {
        Dialog.confirm({
          content: (
            <>
              <div className={styles['lucky-draw-content-message']} style={{ marginTop: '3.91rem' }}>很遗憾 <br />你在游戏里获得{that.score}分</div>
            </>
          ),
          confirmText: (
            <div className={styles['not-winning']} > 再试一次</div>),
          cancelText: (
            <div className={styles['not-winning']} > 返回首页</div>),
          bodyClassName: styles['game-over'],
          onConfirm: () => {
            window["wx"].miniProgram.navigateTo({
              url: '/pages/game/game'
            });
          },
          onCancel: () => {
            window["wx"].miniProgram.navigateTo({
              url: '/pages/index/index'
            });
          }
        });
      } else {
        Dialog.alert({
          content: (
            <>
              <div className={styles['lucky-draw-content-score']}>在游戏里获得了{this.score}分</div>
              <div className={styles['lucky-draw-content-message']}>恭喜获得一次抽奖机会</div>
            </>
          ),
          confirmText: (
            <div className={styles['start']}>
              <div className={styles['over-confirm']} > 试试手气</div> </div>),
          bodyClassName: styles['game-over'],
          onConfirm: async () => {
            //重置游戏条件
            this.setState({ timeUsed: 10 });
            this.handleUpdateScore(0);
            this.score = 0;

            await this.tryGoldEgg();
          }
        });
      }
    }, 2000);
  };

  private addScore = (n: number) => {
    this.score += n;
    this.handleUpdateScore(this.score);
  };

  /** 移除 sprite */
  private removeSprite = (index: number) => {
    const { fallingElements } = this;
    const { sprite } = fallingElements[index];
    fallingElements.splice(index, 1);
    this.app.stage.removeChild(sprite);
    sprite.destroy();
  };

  /** 移除 +1 元素 */
  private removePlus = (index: number) => {
    const { plusElements } = this;
    const sprite = plusElements[index];
    plusElements.splice(index, 1);
    this.app.stage.removeChild(sprite);
    sprite.destroy();
  };

  /** 清理物品掉落定时器 */
  private clearBlockTimer = () => {
    if (this.blockTimer) {
      clearTimeout(this.blockTimer);
      this.blockTimer = null;
    }
  };

  private freshTime() {
    const interval = setInterval(() => {
      if (this.state.timeUsed <= 0) {
        clearInterval(interval);
        this.stop(END_STATUS.TIMEOUT);
      } else {
        this.handleUpdateTimeUsed(this.state.timeUsed - 1);
      }
    }, 1000);
  }

  /** 游戏开始倒计时 */
  private countDownTicking = (sec: number) => {
    if (sec < 5) {
      const timeReady = [
        TIME_READY.NONE,
        TIME_READY.THREE,
        TIME_READY.TWO,
        TIME_READY.ONE,
        TIME_READY.GO,
      ][sec];
      // 保证图片在销毁后再渲染下一张, 避免图片闪烁
      this.setState({ timeReady: TIME_READY.NONE }, () => {
        this.setState({ timeReady });
      });
    } else {
      this.setState(
        { timeReady: TIME_READY.NONE },
        this.start
      );
    }
  };

  /** 倒计时渲染 */
  private renderReadyTime = () => {
    const { timeReady } = this.state;
    const counts = [
      GAME_MAIN_IMAGE_COUNT_DOWN_THREE,
      GAME_MAIN_IMAGE_COUNT_DOWN_TWO,
      GAME_MAIN_IMAGE_COUNT_DOWN_ONE,
      GAME_MAIN_IMAGE_COUNT_DOWN_GO,
    ];
    const nums = {
      [TIME_READY.THREE]: counts[0],
      [TIME_READY.TWO]: counts[1],
      [TIME_READY.ONE]: counts[2],
      [TIME_READY.GO]: counts[3],
    };
    const num = nums[timeReady];

    return counts.map(key => {
      const ImageReadyTime = key;
      return (
        <>
          {ImageReadyTime && (
            <CSSTransition
              in={num === key}
              unmountOnExit
              timeout={400}
              classNames={{
                enter: styles.animate__animated,
                enterActive: styles.animate__zoomIn,
                exit: styles.animate__animated,
                exitActive: styles.animate__zoomOut,
              }}>
              <img src={ImageReadyTime} />
            </CSSTransition>
          )}
        </>
      );
    });
  };

  /** 启动游戏 */
  public start = () => {
    this.setState({ showTip: false });
    this.setStage(STAGE.RUNNING);
    this.appendItemSprite();
    this.startFallingSprite();
    this.freshTime();
  };


  private addElements() {
    const loader = new PIXI.Loader();
    // Chainable `add` to enqueue a resource
    loader.add('logo1', IMAGE.logo1)
      .add('logo2', IMAGE.logo2)
      .add('logo3', IMAGE.logo3)
      .add('logo4', IMAGE.logo4)
      .add('logo5', IMAGE.logo5)
      .add('logo6', IMAGE.logo6)
      .add('drinks1', IMAGE.drinks1)
      .add('bomb', IMAGE.bomb)
      .add('clock', IMAGE.clock)
      .add('icecream', IMAGE.icecream)

    loader.load((loader, resources: any) => {
      // resources is an object where the key is the name of the resource loaded and the value is the resource object.
      // They have a couple default properties:
      // - `url`: The URL that the resource was loaded from
      // - `error`: The error that happened when trying to load (if any)
      // - `data`: The raw data that was loaded
      // also may contain other properties based on the middleware that runs.
    });

    // throughout the process multiple signals can be dispatched.
    loader.onProgress.add(() => { }); // called once per loaded/errored file
    loader.onError.add(() => { }); // called once per errored file
    loader.onLoad.add(() => { }); // called once per loaded file
    loader.onComplete.add(() => {

    });
  }

  private initGameSetting() {
    const { app } = this;

    app.resizeTo = this.refView.current as HTMLElement;
    app.resize();
    app.stage.interactive = true;
    if (!('events' in app.renderer) && 'addSystem' in app.renderer) {
      // app.renderer.addSystem(EventSystem as any, 'events');
    }

    this.addElements();

  }

  /** 初始化拖拽元素 */
  private initDragElement() {
    const texture = this.createProxyImageSprite(IMAGE.DRAGON);
    const sprite = new PIXI.Sprite(texture);
    const viewSize = this.getViewSize();
    this.dragSprite = sprite;
    this.setDragSpriteAxis();

    sprite.interactive = true;

    sprite.addListener('pointerdown', this.onDragStart);
    sprite.addListener('pointerup', this.onDragEnd);
    sprite.addListener('pointerupoutside', this.onDragEnd);

    // 增强拖拽区域
    // eslint-disable-next-line no-case-declarations
    const rect = new PIXI.Sprite();
    rect.x = 0;
    rect.y = 0;
    rect.width = viewSize.width;
    rect.height = viewSize.height;
    this.app.stage.addChild(rect);
    // 位于上层
    this.app.stage.addChild(sprite);
  }

  /** 获取尺寸缩放比 */
  private getZoom(): number {
    const viewSize = this.getViewSize();
    const zoom = viewSize.width / 320;

    return zoom;
  }

  /** 计算承接物坐标相关尺寸 */
  private setDragSpriteAxis() {
    const sprite = this.dragSprite;
    console.log(this);
    if (typeof sprite === 'undefined') return;

    const { width, height } = { width: 272 / 2, height: 166 / 2 }
    const viewSize = this.getViewSize();
    const zoom = this.getZoom();

    sprite.width = width;
    sprite.height = height;

    sprite.y = viewSize.height - (height + 60 * zoom);
    sprite.x = viewSize.width / 2 -  width / 2;
  }

  /** 接粽子拖拽 - 手指按下 */
  private onDragStart = () => {
    this.app.stage.addListener('pointermove', this.onDragMove);
  };

  /** 接粽子拖拽 - 结束 */
  private onDragEnd = () => {
    this.app.stage.removeListener('pointermove', this.onDragMove);
  };

  /** 接粽子拖拽 - 拖动 */
  private onDragMove = e => {
    if (this.dragSprite) {
      const { width } = this.dragSprite;
      const maxX = this.app.stage.width - width;
      let x = e.data.global.x - width / 2;
      x = x < 0 ? 0 : x > maxX ? maxX : x;
      this.dragSprite.position.x = x;
    }
  };

  /** 游戏初始化 */
  public init() {
    if (this.isInit) return;

    this.initGameSetting();
    this.initDragElement();

  }

  private createGame() {
    // Create the application helper and add its render target to the page

    let app = new PIXI.Application({
      width: 414,
      // height: 896,
      resolution: 2,
      antialias: true, // 消锯齿
      autoDensity: true,
      backgroundAlpha: 0
    });
    this.app = app;
    document.getElementById('myCanvas')?.appendChild(app.view);
  }


  /** 设置游戏阶段 */
  private setStage = (stage: STAGE) => {
    this.stage = stage;
  };

  /** 更新游戏时长 */
  private handleUpdateTimeUsed = (timeUsed: number) => {
    this.setState({ timeUsed });
  };

  /** 更新游戏得分 */
  private handleUpdateScore = (score: number) => {
    this.setState({ score });
  };

  private touchStartHandler = () => {
    console.log('砸破鸡蛋!');
    setTimeout(async () => {
      this.setState({
        showGoldEgg: false
      });
      await this.handleDraw();
    }, 3000);
  };

  render() {
    let { timeUsed, score, showTip, tip, goldEggConfig, showGoldEgg } = this.state;

    return <div ref={this.refView} id="myCanvas" className={styles['container']}>
      {/* 倒数计时 */}
      <div className={styles['time-used']}>游戏时间:{timeUsed}秒</div>
      {/* 得分状态 */}
      <div className={styles['score']}>获得分数: {score}分</div>
      {/* */}
      {showTip && <Tip start={this.start}>jack</Tip>}
      <Add text={tip}></Add>
      {showGoldEgg && <GoldEgg config={goldEggConfig} touchStart={this.touchStartHandler}></GoldEgg>}
    </div>
  }
};


export const GamePage = withTranslation()(GamePageComponent);
