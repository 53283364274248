export const sideMenuList = [
  {
    title: "主题旅游",
    subMenu: [
      {
        title: "爸妈游",
        subMenu: ["祈福拜佛", "古镇游玩", "桂林山水", "红色之旅"],
      },
      {
        title: "亲子游",
        subMenu: ["上海迪士尼", "探险体验", "海洋公园", "感受名校"],
      },
      {
        title: "蜜月游",
        subMenu: ["浪漫海岛", "豪华酒店", "购物血拼", "全球婚礼"],
      },
    ],
  },
  {
    title: "周边游",
    subMenu: [
      {
        title: "周边目的地",
        subMenu: ["杭州", "南京", "苏州", "黄山"],
      },
      {
        title: "热门景点",
        subMenu: ["普陀山", "千岛湖", "乌镇古镇", "宏村"],
      },
    ],
  },
  {
    title: "国内游",
    subMenu: [
      {
        title: "广东",
        subMenu: ["广州", "深圳", "珠海", "惠州"],
      },
      {
        title: "浙江",
        subMenu: ["杭州", "普陀山", "千岛湖", "宁波"],
      },
      {
        title: "江苏",
        subMenu: ["舟山", "乌镇", "干山", "湖州"],
      },
    ],
  },
  {
    title: "东南亚",
    subMenu: [
      {
        title: "热门目的地",
        subMenu: ["新加坡", "巴厘岛", "越南", "柬埔寨"],
      },
      {
        title: "热门景点",
        subMenu: ["下龙湾", "吴哥窟", "西哈努克", "槟城珍珠岛"],
      },
    ],
  },
  {
    title: "欧洲",
    subMenu: [
      {
        title: "热门邮轮航线",
        subMenu: ["欧洲河轮", "北欧邮轮", "爱琴海邮轮", "英伦三岛"],
      },
      {
        title: "热门景点",
        subMenu: ["贝加尔湖", "普罗旺斯", "阿尔卑斯", "圣托里尼岛"],
      },
      {
        title: "蜜月游",
        subMenu: ["极光中心", "俄罗斯远东地区", "英格兰少女峰", "玻璃海滩"],
      },
    ],
  },
];

/**
 * 首页推荐产品数据
 * */
// 爆款推荐
export const productList1 = [
  {
    id: 1,
    title:
      "埃及阿斯旺+卢克索+红海Red Sea+开罗+亚历山大12日跟团游(5钻)·【官方旗舰明星纯玩团】25人封顶|含签证小费全程餐|3晚尼罗河游轮+3晚红海全包度假村+1晚底比斯古都|升级内陆飞机|优质中文导游队伍|七大神庙+赠项目",
    price: "11990",
  },
  {
    id: 2,
    title: "摩洛哥撒哈拉沙漠+卡萨布兰卡+马拉喀什+舍夫沙...",
    price: "13290",
  },
  {
    id: 3,
    title: "越南胡志明市+美奈+芽庄+河内7日6晚跟团游(4钻)...",
    price: "4000",
  },
  {
    id: 4,
    title: "迪拜+阿布扎比6日跟团游(5钻)·【携程国旅纯玩...",
    price: "7399",
  },
  {
    id: 5,
    title: "泰国曼谷+芭堤雅6日5晚跟团游(5钻)·【纯玩】『可...",
    price: "3499",
  },
  {
    id: 6,
    title: "日本大阪+京都+箱根+东京6日5晚跟团游(4钻)·【浪...",
    price: "5999",
  },
  {
    id: 7,
    title: "新加坡+马来西亚6日5晚跟团游(5钻)·【纯玩无购物...",
    price: "6199",
  },
  {
    id: 8,
    title: "法国+德国+意大利+瑞士12日跟团游(4钻)·【匠心定...",
    price: "13699",
  },
  {
    id: 9,
    title: "印度尼西亚巴厘岛7日5晚私家团(5钻)·A线独栋泳...",
    price: "5021",
  },
];
