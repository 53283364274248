import React from "react";
import styles from "./Partner.module.css";
import { Row, Col, Divider, Typography } from "antd";

import partnerImage1 from "../../assets/images/partner-facebook.png";
import partnerImage2 from "../../assets/images/partner-ins.png";
import partnerImage3 from "../../assets/images/partner-microsoft.png";
import partnerImage4 from "../../assets/images/partner-youtube.png";

const partnerArr = [
  { src: partnerImage1, title: "Microsoft" },
  { src: partnerImage2, title: "Youtube" },
  { src: partnerImage3, title: "Ins" },
  { src: partnerImage4, title: "Facebook" },
];

export const Partner: React.FC = () => {
  return (
    <div className={styles["partner-container"]}>
      <Divider orientation="left">
        <Typography.Title level={3} type="warning">
          合作伙伴
        </Typography.Title>
      </Divider>
      <Row>
        {partnerArr.map((c, index) => (
          <Col span={6}>
            <img alt={c.title} src={c.src} height={120} width={240} />
          </Col>
        ))}
      </Row>
    </div>
  );
};
