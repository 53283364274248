import React from 'react';
import {
  Header,
  SideMenu,
  Carousel,
  ProductCollection,
  Partner,
  Footer,
} from '../../components';
import { Row, Col, Typography } from 'antd';

import { productList1, productList2, productList3 } from './mockups';
import sideImage1 from '../../assets/images/sider_2019_12-09.png';
import sideImage2 from '../../assets/images/sider_2019_02-04.png';
import sideImage3 from '../../assets/images/sider_2019_02-04-2.png';
import styles from './HomePage.module.css';
import { withTranslation, WithTranslation } from 'react-i18next';

class HomePageComponent extends React.Component<WithTranslation> {
  render() {
    // console.log(this.props.t)
    const { t } = this.props;
    return (
      <div>
        <Header></Header>
        {/* 页面内容content */}
        <div className={styles['page-content']}>
          <Row style={{ marginTop: 20 }}>
            {/* 左侧三级菜单 */}
            <Col span={6}>
              <SideMenu />
            </Col>
            {/* 右侧跑马灯 */}
            <Col span={18}>
              <Carousel />
            </Col>
          </Row>

          {/* 爆款推荐 */}
          <ProductCollection
            title={
              <Typography.Title level={3} type="warning">
                {t('home_page.hot_recommended')}
              </Typography.Title>
            }
            sideImage={sideImage1}
            products={productList1}
          />

          {/* 新品上市 */}
          <ProductCollection
            title={
              <Typography.Title level={3} type="danger">
                {t('home_page.new_arrival')}
              </Typography.Title>
            }
            sideImage={sideImage2}
            products={productList2}
          />

          {/* 国内游推荐 */}
          <ProductCollection
            title={
              <Typography.Title level={3} type="success">
                {t('home_page.domestic_travel')}
              </Typography.Title>
            }
            sideImage={sideImage3}
            products={productList3}
          />
        </div>
        {/* 合作伙伴 */}
        <Partner></Partner>
        {/* 底部 */}
        <Footer></Footer>
      </div>
    );
  }
}

export const HomePage = withTranslation()(HomePageComponent);
