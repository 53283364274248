import React from 'react';
import styles from './SideMenu.module.css';
import { sideMenuList } from './mockup';
import { Menu } from 'antd'
import { GiftOutlined } from '@ant-design/icons'


export const SideMenu: React.FC = () => {
  return (
    <Menu mode="vertical" className={styles[`side-menu`]}>
      {sideMenuList.map((m, index) => (
        <Menu.SubMenu
          key={`side-memu-${index}`}
          title={
            <span>
              <GiftOutlined />
              {m.title}
            </span>}
        >
          {m.subMenu.map((sm, smindex) => (
            <Menu.SubMenu
              key={`sub-memu-${smindex}`}
              title={
                <span>
                  <GiftOutlined />
                  {sm.title}
                </span>}
            >
              {sm.subMenu.map((sms, smsindex) => (
                <Menu.Item
                  key={`sub-sub-memu-${smsindex}`}
                >
                  <span>
                    <GiftOutlined />
                    {sms}
                  </span>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ))}
        </Menu.SubMenu>
      ))}
    </Menu>
  )
}